import { css } from '@emotion/react'
import { format } from 'date-fns'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef, Fragment } from 'react'

import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

interface Props extends ComponentPropsWithoutRef<'li'> {
  data?: Queries.AthleticCalendarEventFragment | null
  oddEven?: 'ODD' | 'EVEN'
}

export const AthleticCalendarEvent = ({
  data,
  oddEven,
  ...props
}: Props): JSX.Element => {
  const getDate = (date: Date) => {
    const timeArray = data?.startTime.split(/[:\s]/)
    if (timeArray) {
      date.setHours(
        parseInt(timeArray[0]) +
          (timeArray[2] === 'PM' && timeArray[0] !== '12' ? 12 : 0)
      )
      date.setMinutes(parseInt(timeArray[1]))
    }
    return date
  }
  const eventDate = data?.date && getDate(new Date(data.date))
  const day = eventDate && format(eventDate, 'd')
  const getDayOfWeek = (date: Date) => {
    const dayOfWeek = format(date, 'EEEEEE')
    switch (dayOfWeek) {
      case 'Mo':
        return 'M'
      case 'We':
        return 'W'
      case 'Fr':
        return 'F'
      default:
        return dayOfWeek
    }
  }
  const getTime = (date: Date) => {
    const hour = format(date, 'h')
    const minute = format(date, 'mm')
    const ampm = format(date, 'aaa')
    if (minute !== '00') {
      return `${hour}:${minute}${ampm}`
    } else return `${hour}${ampm}`
  }
  const getTitle = () => {
    if (data?.title) {
      return data?.title
    }
    if (data?.isPractice) {
      return 'Practice'
    } else {
      return `Game vs. ${data?.opponent}`
    }
  }
  const getRescheduledDate = () => {
    if (data?.isRescheduled) {
      const newDate =
        data?.rescheduledDate && new Date(data.rescheduledDate)
      return (
        <Fragment>
          {newDate && format(getDate(newDate), 'EEE, MMM d')} at{' '}
          {newDate && getTime(newDate)}
        </Fragment>
      )
    }
  }
  const styles = {
    event: css`
      display: grid;
      grid-template-columns: auto 1fr auto;
      grid-column-gap: 0.5em;
      padding: 1.5em 0 1.67em;
      ${oddEven === 'ODD' &&
      css`
        background: ${colors.gray95};
      `}
      ${oddEven === 'EVEN' &&
      css`
        background: ${colors.gray98};
      `}
      ${mq().s} {
        grid-column-gap: 0;
      }
    `,
    date: css`
      display: grid;
      align-self: flex-start;
      align-content: center;
      justify-items: center;
      font-size: var(--fs-48);
      width: 2.5em;
      padding: 0 0.25em;
      box-sizing: border-box;
      ${mq().s} {
        width: 2em;
      }
    `,
    day: css`
      display: block;
      font-family: var(--ff-display);
      line-height: 1;
      margin: 0;
      ${(data?.isRescheduled || data?.isCancelled) &&
      css`
        text-decoration: line-through;
        text-decoration-thickness: 2px;
      `}
    `,
    dayOfWeek: css`
      display: block;
      font-size: var(--fs-16);
      font-weight: 450;
      color: ${colors.gray65};
    `,
    info: css`
      padding-right: 1.5em;
    `,
    title: css`
      font-size: var(--fs-24);
      font-weight: 350;
      margin: 0.125em 0 0.333em;
      ${data?.isCancelled &&
      css`
        span:first-of-type {
          text-decoration: line-through;
          text-decoration-thickness: 1px;
        }
      `}
    `,
    details: css`
      font-size: var(--fs-18);
      color: ${colors.gray45};
      display: block;
      line-height: 1.333;
      a {
        color: ${colors.red};
        text-underline-offset: 0.15em;
        text-decoration-thickness: 1px;
        @media (hover: hover) {
          &:hover {
            color: ${colors.redDark};
          }
        }
      }
      > span,
      > a {
        display: inline-block;
      }
    `,
    winOrLoss: css`
      font-size: var(--fs-18);
      padding-right: 1.5em;
      margin: 0.275em 0;
      > span {
        &:first-of-type {
          font-weight: 450;
          text-transform: uppercase;
          ${data?.winOrLoss === 'Win' &&
          css`
            color: ${colors.red};
          `}
          ${data?.winOrLoss === 'Loss' &&
          css`
            color: ${colors.gray45};
          `}
          ${data?.winOrLoss === 'Tie' &&
          css`
            color: ${colors.gray30};
          `}
        }
        &:last-of-type {
          font-weight: 350;
          color: ${colors.gray45};
          font-style: italic;
        }
      }
    `,
    cancelled: css`
      color: ${colors.red};
      font-size: var(--fs-18);
      text-transform: uppercase;
      font-weight: 450;
    `,
    rescheduled: css`
      color: ${colors.red};
      font-size: var(--fs-15);
      text-transform: uppercase;
      font-weight: 500;
    `,
  }
  return (
    <li
      css={styles.event}
      {...props}
    >
      <div css={styles.date}>
        <span css={styles.day}>{day}</span>
        <span css={styles.dayOfWeek}>
          {eventDate && getDayOfWeek(eventDate)}
        </span>
      </div>
      <div css={styles.info}>
        <h4 css={styles.title}>
          <span>{getTitle()}</span>
          {data?.isCancelled && (
            <span css={styles.cancelled}>&ensp;Cancelled</span>
          )}
        </h4>
        <div css={styles.details}>
          {data?.isRescheduled && !data?.isCancelled ? (
            <Fragment>
              <span>
                <span css={styles.rescheduled}>Rescheduled:</span>&ensp;
                {getRescheduledDate()}
              </span>
            </Fragment>
          ) : (
            <span>{eventDate && getTime(eventDate)}</span>
          )}
          <span>&ensp;|&ensp;</span>
          {data?.mapUrl ? (
            <a
              href={data?.mapUrl}
              target="_blank"
              rel="noreferrer"
            >
              {data?.location}
            </a>
          ) : (
            <span>{data?.location || 'TBD'}</span>
          )}
        </div>
      </div>
      {data?.winOrLoss && (
        <div css={styles.winOrLoss}>
          <span>{data?.winOrLoss}</span>{' '}
          <span>
            ({data?.teamScore}-{data?.opponentScore})
          </span>
        </div>
      )}
    </li>
  )
}
export const AthleticCalendarEventFragment = graphql`
  fragment AthleticCalendarEvent on AthleticCalendarEvent {
    __typename
    date
    dismissalTime
    endTime
    homeOrAway
    isCancelled
    isPractice
    isRescheduled
    location
    mapUrl
    opponent
    opponentScore
    originalId
    rescheduledDate
    rescheduledNote
    startTime
    teamId
    teamName
    sportName
    teamScore
    title
    winOrLoss
  }
`
